import { Spin } from 'antd';
import { DAILY_PAGE } from 'common/constants';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDailyCount, updateFilters } from 'redux/thunks';
import './SBDailyCount.scss';

const SBDailyCount = ({ serviceBoatId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { data: dailyCount, loading = false } = useSelector((s) => s.dailyCount);
    const initialFilters = useSelector((s) => s.filter.data);

    useEffect(() => {
        const filter = {
            serviceBoatIds: serviceBoatId ? [serviceBoatId] : [],
            fromDate: moment().startOf('day'),
            toDate: moment().endOf('day')
        };
        dispatch(getDailyCount(filter));
    }, [serviceBoatId]);

    const handleDailyCountClick = async () => {
        await dispatch(
            updateFilters({
                ...initialFilters,
                serviceBoatIds: serviceBoatId ? [serviceBoatId] : [],
                dailyFromDate: moment().startOf('day'),
                dailyToDate: moment().endOf('day')
            })
        ).unwrap();

        navigate(DAILY_PAGE);
    };

    return (
        <div className="daily-count text-center" onClick={handleDailyCountClick}>
            <p className="text-white text-3xl font-semibold mb-3">
                {loading ? <Spin /> : dailyCount.count}
            </p>
            <p className="text-md text-slate-300">{t('dailyCountButton.viewTodayReport')}</p>
        </div>
    );
};

SBDailyCount.propTypes = {
    serviceBoatId: PropTypes.string
};

export default SBDailyCount;
