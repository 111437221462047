import { Button, Modal } from 'antd';
import { BUTTON_BLUE_CLASS, BUTTON_WHITE_CLASS } from 'common/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SBDialogBox = ({ showDialog, cancelNavigation, confirmNavigation, message }) => {
    const { t } = useTranslation();

    return (
        <Modal
            open={showDialog}
            onOk={confirmNavigation}
            onCancel={cancelNavigation}
            closable={true}
            footer={[
                <Button
                    onClick={cancelNavigation}
                    className={`${BUTTON_WHITE_CLASS} !inline`}
                    key="1"
                >
                    {t('dialogBox.cancel')}
                </Button>,
                <Button
                    onClick={confirmNavigation}
                    className={`${BUTTON_BLUE_CLASS} !inline`}
                    key="2"
                >
                    {t('dialogBox.leave')}
                </Button>
            ]}
        >
            {message ? t(message) : t('dialogBox.defaultMessage')}
        </Modal>
    );
};

export default SBDialogBox;
