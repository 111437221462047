import { PicCenterOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getServiceBoatListOnNavbar } from 'redux/thunks';
import { SERVICE_BOAT_DETAIL_PAGE } from 'common/constants';

const ServiceBoatList = () => {
    const dispatch = useDispatch();

    const { data: serviceBoats = [] } = useSelector((s) => s.serviceBoatList || []);

    useEffect(() => {
        dispatch(getServiceBoatListOnNavbar());
    }, []);

    return (
        <Menu mode="horizontal" selectable={false} className="header-site-menu">
            {serviceBoats.map((boat, index) => (
                <Menu.Item key={`${boat.id} - ${index}`} disabled={!boat.hasData}>
                    <Link rel="noopener noreferrer" to={`${SERVICE_BOAT_DETAIL_PAGE}/${boat.id}`}>
                        <PicCenterOutlined />
                        <span>{boat.name}</span>
                    </Link>
                </Menu.Item>
            ))}
        </Menu>
    );
};

export default ServiceBoatList;
