import moment from 'moment';
import { FORMAT_DATE, FORMAT_TIME } from 'common/constants';
import PropTypes from 'prop-types';

export const formatDateTime = (value, format = FORMAT_DATE) => {
    if (!value) return;
    return moment(value).format(format);
};

export const formatSeconds = (seconds) => moment.utc(seconds * 1000).format(FORMAT_TIME);

export const getPreviousDate = (n) => moment().startOf('day').subtract(n, 'days');

export const calculateTimeDifference = (startTime, stopTime) => {
    if (!startTime || !stopTime) {
        return '-';
    }
    const start = moment(startTime);
    const stop = moment(stopTime);

    const duration = moment.duration(stop.diff(start));

    const hours = (duration.hours() + duration.days() * 24).toString().padStart(2, '0');
    const minutes = duration.minutes().toString().padStart(2, '0');
    const seconds = duration.seconds().toString().padStart(2, '0');

    return `${hours}.${minutes}.${seconds}`;
};

export const getDifferenceInSeconds = (startTime, stopTime) => {
    return Math.round((moment(stopTime) - moment(startTime)) / 1000);
};

calculateTimeDifference.propTypes = {
    startTime: PropTypes.string,
    stopTime: PropTypes.string
};
