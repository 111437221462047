export const FORMAT_DATE = 'DD/MM/YYYY HH:mm:ss';
export const FORMAT_TIME = 'HH:mm:ss';
export const FORMAT_DATE_NOTIME = 'DD/MM/YYYY';
export const YYYY = 'YYYY';
export const API_FORMAT_DATE = 'YYYY-MM-DD';
export const FILE_EXPORT_FORMAT_DATE = 'YYYYMMDDHHmmss';

export const NORWEGIAN_DATE_FORMAT = 'DD.MM.YYYY';
export const NORWEGIAN_DATE_TIME_FORMAT = 'DD.MM.YYYY HH.mm';
export const NORWEGIAN_FULL_TIME_FORMAT = 'DD.MM.YYYY HH.mm.ss';
