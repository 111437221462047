export const formatNumberToFixedDigits = (number, digit = 2) => {
    if (isNaN(number)) {
        return number;
    }

    return parseFloat(Number(number).toFixed(digit));
};

export const getRandomBoolean = () => {
    const THRESHOLD = 128;

    return crypto.getRandomValues(new Uint8Array(1))[0] < THRESHOLD;
};
