import { SBGeneralInfo } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const GeneralInfo = () => {
    const { t } = useTranslation();
    const { data: profile = {}, loading = false } = useSelector((s) => s.profile);

    const infoList = [
        {
            key: t('dashboard.generalInformation.companyName'),
            value: profile.company?.name
        },
        {
            key: t('dashboard.generalInformation.name'),
            value: profile.name
        },
        {
            key: t('dashboard.generalInformation.userRole'),
            value: profile.roles
        }
    ];

    return (
        <SBGeneralInfo
            loading={loading}
            title={t('dashboard.generalInformation.title')}
            infoList={infoList}
            logoUrl={profile.company?.logoUrl}
        />
    );
};

export default GeneralInfo;
