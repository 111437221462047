import { createAsyncThunk } from '@reduxjs/toolkit';
import { EVENT_CHART_API } from 'common/constants';
import { API } from 'services';

export const getEventChart = createAsyncThunk(
    'event/getEventChart',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.get(EVENT_CHART_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);