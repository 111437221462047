import { Bar } from '@ant-design/plots';
import PropTypes from 'prop-types';

const SBBar = ({ data = [], colors, height, formatValue }) => {
    const config = {
        data,
        height,
        xField: 'value',
        yField: 'name',
        seriesField: 'name',
        color: colors,
        animation: false,
        label: {
            position: 'right',
            formatter: (datum) => (formatValue ? formatValue(datum.value) : datum.value)
        },
        appendPadding: [0, 40, 0, 0],
        legend: false,
        xAxis: {
            label: {
                style: {
                    fontSize: 11,
                    fill: 'black'
                },
                formatter: (value) => (formatValue ? formatValue(value) : value)
            }
        },
        yAxis: {
            label: {
                style: {
                    fontSize: 11,
                    fill: 'black'
                }
            }
        },
        tooltip: {
            formatter: (datum) => ({
                name: 'Value',
                value: formatValue ? formatValue(datum.value) : datum.value
            })
        }
    };

    return <Bar {...config} />;
};

SBBar.propTypes = {
    data: PropTypes.array,
    colors: PropTypes.array,
    height: PropTypes.number,
    formatValue: PropTypes.func
};

export default SBBar;
