import { Card, Slider } from 'antd';
import { BLUE } from 'common/constants';
import { formatSeconds, getDifferenceInSeconds } from 'common/utils';
import propTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './SliderFilter.scss';

const SliderFilter = ({ eventThrow, onSliderValuesChange = () => {} }) => {
    const { data: event } = useSelector((s) => s.eventDetail);

    const [sliderValues, setSliderValues] = useState([]);
    const [sliderRange, setSliderRange] = useState([0, 0]);

    useEffect(() => {
        const { startTime, stopTime } = eventThrow ? eventThrow : event;

        const startSecond = getDifferenceInSeconds(event.startTime, startTime);
        const stopSecond = getDifferenceInSeconds(event.startTime, stopTime);

        setSliderValues([startSecond, stopSecond]);
        setSliderRange([startSecond, stopSecond]);

        onSliderValuesChange([startSecond, stopSecond]);
    }, [event.id, eventThrow]);

    const getDefaultMarks = (numberOfValues = 6) => {
        const step = (sliderRange[1] - sliderRange[0]) / (numberOfValues - 1);
        let marks = {};

        for (let i = 0; i < numberOfValues; i++) {
            const value = Math.round(sliderRange[0] + i * step);
            marks = { ...marks, [value]: formatSeconds(value) };
        }

        return marks;
    };

    const handleSliderChange = (values) => {
        setSliderValues(values);
    };

    const handleAfterChange = (values) => {
        onSliderValuesChange(values);
    };

    return (
        <Card className="slider-filter mt-2 px-5">
            <Slider
                min={sliderRange[0]}
                max={sliderRange[1]}
                range={{ draggableTrack: true }}
                value={sliderValues}
                marks={getDefaultMarks()}
                tooltip={{
                    open: true,
                    formatter: (value) => formatSeconds(value)
                }}
                trackStyle={{ backgroundColor: BLUE, height: 8, bottom: 2 }}
                handleStyle={{
                    borderColor: BLUE
                }}
                onChange={handleSliderChange}
                onAfterChange={handleAfterChange}
                controlSize={24}
            />
        </Card>
    );
};

SliderFilter.propTypes = {
    eventThrow: propTypes.object,
    onSliderValuesChange: propTypes.func
};

export default SliderFilter;
