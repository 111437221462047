import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { AbilityContext } from 'casl';
import defineAbilityFor from 'casl/ability';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    PointElement,
    Title,
    Tooltip
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ProtectedRoute, routes } from 'routes';
import './index.scss';
import { Organization, Unauthorized } from 'pages';

ChartJS.register(CategoryScale, LinearScale, Title, Tooltip, Legend, PointElement, ChartDataLabels);

function App() {
    const { pathname } = useLocation();
    const { accounts } = useMsal();

    const abilities = defineAbilityFor(accounts[0]?.idTokenClaims?.roles || []);

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
        });
    }, [pathname]);

    const renderRoute = (route, key, renderRoute = () => {}) => (
        <Route
            path={route.path}
            key={key}
            element={
                <ProtectedRoute isPrivate={route.isPrivate} role={route.role}>
                    {route.element}
                </ProtectedRoute>
            }
        >
            {route.children &&
                route.children.map((child, index) =>
                    renderRoute(child, `${key}_${index}`, renderRoute)
                )}
        </Route>
    );

    if (accounts?.length && !accounts[0].idTokenClaims?.roles?.length) {
        return <Unauthorized />;
    }

    return (
        <>
            <AuthenticatedTemplate>
                <AbilityContext.Provider value={abilities}>
                    <Routes>
                        {routes.map((route, index) => renderRoute(route, index, renderRoute))}
                    </Routes>
                </AbilityContext.Provider>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Organization />
            </UnauthenticatedTemplate>
        </>
    );
}

export default App;
