import { SBItemList } from 'components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SERVICE_BOAT_DETAIL_PAGE } from 'common/constants';

const ServiceBoatList = () => {
    const { t } = useTranslation();
    const { data: boats = [], loading = false } = useSelector((s) => s.serviceBoatList);

    const itemList = boats?.map((boat) => {
        return {
            id: boat.id,
            name: boat.name,
            path: `..${SERVICE_BOAT_DETAIL_PAGE}/${boat.id}`,
            hasData: boat.hasData
        };
    });

    return (
        <div className="mt-5">
            <SBItemList
                loading={loading}
                title={t('dashboard.boatList.title')}
                itemList={itemList}
            />
        </div>
    );
};

export default ServiceBoatList;
