import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import AppWrapper from './App';
import { msalInstance } from './authConfig';
import store from './redux';
import i18n from './translation/i18n';

const RouteAdapter = ({ children }) => {
    const navigate = useNavigate();
    const routerLocation = useLocation();

    const adaptedHistory = React.useMemo(
        () => ({
            replace(location) {
                navigate(location, { replace: true, state: location.state });
            },
            push(location) {
                navigate(location, { replace: false, state: location.state });
            }
        }),
        [navigate]
    );
    if (!children) {
        return null;
    }
    return children({ history: adaptedHistory, location: routerLocation });
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <QueryParamProvider ReactRouterRoute={RouteAdapter}>
                <MsalProvider instance={msalInstance}>
                    <I18nextProvider i18n={i18n}>
                        <AppWrapper />
                    </I18nextProvider>
                </MsalProvider>
            </QueryParamProvider>
        </BrowserRouter>
    </Provider>
);
