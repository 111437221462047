import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_AUTH_CLIENT_ID,
        redirectUri: '/',
        navigateToLoginRequestUrl: true,
        postLogoutRedirectUri: '/'
    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        // console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        break;
                }
            }
        }
    }
};

export const loginRequest = {
    scopes: [process.env.REACT_APP_MSAL_LOGIN_REQUEST_SCOPES]
};

export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
};

export const msalInstance = new PublicClientApplication(msalConfig);
