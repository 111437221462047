import { Card, Col, Image, Row, Spin } from 'antd';
import defaultCompanyLogo from 'assets/Default_Company_Logo.jpg';
import { Title } from 'common/constants';
import PropTypes from 'prop-types';
import './SBGeneralInfo.scss';
import { SBDailyCount } from 'components';
import { useTranslation } from 'react-i18next';

const SBGeneralInfo = ({
    loading = false,
    title = '',
    infoList = [],
    logoUrl = '',
    serviceBoatId
}) => {
    const { t } = useTranslation();

    return (
        <div>
            <Title level={4}>{title}</Title>
            <div className="general-info">
                <Spin spinning={loading}>
                    <Card bordered className="text-base">
                        <Row gutter={8}>
                            <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={7}
                                xl={5}
                                xxl={4}
                                className="flex justify-center items-center avatar"
                            >
                                <Image
                                    className="rounded-sm border-inherit object-contain"
                                    style={{ border: '1px #c7c9cb solid' }}
                                    width={150}
                                    height={150}
                                    alt="Company logo"
                                    src={logoUrl || defaultCompanyLogo}
                                />
                            </Col>

                            <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={17}
                                xl={17}
                                xxl={12}
                                className="flex items-center"
                            >
                                <div className="w-full">
                                    <Row gutter={8}>
                                        {infoList.map((info, index) => {
                                            return (
                                                <Col
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={8}
                                                    xxl={8}
                                                    className="py-4"
                                                    key={index}
                                                >
                                                    <div className="font-semibold">{info.key}</div>
                                                    <div>{info?.value}</div>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </div>
                            </Col>

                            <Col
                                className="daily-report w-full flex justify-center items-center"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={8}
                            >
                                <div className="flex flex-col">
                                    <span className="font-semibold text-base mb-1">
                                        {t('dailyCountButton.eventCountToday')}
                                    </span>
                                    <SBDailyCount serviceBoatId={serviceBoatId} />
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Spin>
            </div>
        </div>
    );
};

SBGeneralInfo.propTypes = {
    loading: PropTypes.bool,
    title: PropTypes.string,
    infoList: PropTypes.array,
    logoUrl: PropTypes.string,
    serviceBoatId: PropTypes.string
};

export default SBGeneralInfo;
