import { createAsyncThunk } from '@reduxjs/toolkit';
import { msalInstance } from 'authConfig';
import {
    ENGINE_SERVICE_BOAT_SELECTION_API,
    FARMER_COMPANIES_SELECTION_API,
    FARMER_PENS_SELECTION_API,
    FARMER_SITES_SELECTION_API,
    ORIGINAL_BOATS_SELECTION_API,
    SERVICE_BOAT_SELECTION_API
} from 'common/constants';
import { API, GLOBAL_API } from 'services';

export const getEngineServiceBoatSelection = createAsyncThunk(
    'selections/engineServiceBoatSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(ENGINE_SERVICE_BOAT_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getFarmerCompaniesSelection = createAsyncThunk(
    'selections/farmerCompanySelection',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { localAccountId } = msalInstance.getAllAccounts()[0];
            const { data } = await GLOBAL_API.get(
                `${FARMER_COMPANIES_SELECTION_API}/${localAccountId}`,
                filters
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getFarmerPensSelection = createAsyncThunk(
    'selections/farmerPenSelection',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { localAccountId } = msalInstance.getAllAccounts()[0];
            const { data } = await GLOBAL_API.get(
                `${FARMER_PENS_SELECTION_API}/${localAccountId}`,
                filters
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getFarmerSitesSelection = createAsyncThunk(
    'selections/farmerSiteSelection',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { localAccountId } = msalInstance.getAllAccounts()[0];
            const { data } = await GLOBAL_API.get(
                `${FARMER_SITES_SELECTION_API}/${localAccountId}`,
                filters
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getServiceBoatsSelection = createAsyncThunk(
    'selections/serviceBoatSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(SERVICE_BOAT_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getOriginalBoatsSelection = createAsyncThunk(
    'selections/originalBoatSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(ORIGINAL_BOATS_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
