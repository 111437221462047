import { createSlice } from '@reduxjs/toolkit';
import { getDailyCount } from 'redux/thunks';

const initialState = {
    data: { count: 0 },
    loading: false
};

export const dailyCountSlice = createSlice({
    name: 'dailyCount',
    initialState,
    reducers: {},
    extraReducers: {
        [getDailyCount.pending]: (state) => {
            state.loading = true;
        },
        [getDailyCount.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
        },
        [getDailyCount.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        }
    }
});
