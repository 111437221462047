import { AlignLeftOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { Layout as LibLayout, Spin } from 'antd';
import fishwellLogo from 'assets/Fishwell_Technology_Logo.png';
import { SBGlobalBoundaryError } from 'common/components';
import { LAYOUT_TYPES, SETUP_ACCOUNT_API } from 'common/constants';
import { alertErrorMessage } from 'common/utils';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { getCurrentProfile } from 'redux/thunks';
import { API } from 'services';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';
import StressDetectionFooter from './StressDetectionLayout/Footer';
import StressDetectionHeader from './StressDetectionLayout/Header';
import StressDetectionSidebar from './StressDetectionLayout/Sidebar';

import './Layout.scss';

const { Content } = LibLayout;

const Layout = ({ layout = LAYOUT_TYPES.DEFAULT }) => {
    const dispatch = useDispatch();
    const { accounts } = useMsal();

    const [collapsed] = useState(false);
    const [isSetupAccountLoading, setIsSetupAccountLoading] = useState(true);

    const organizationId = useSelector((s) =>
        get(s, 'profileReducer.data.company.organizationId', '')
    );

    const containerStyle = useMemo(() => {
        return { marginLeft: collapsed ? 80 : 250 };
    }, [collapsed]);

    const ldProviderConfig = {
        clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
        context: {
            kind: 'multi',
            Tenant: {
                key: get(accounts[0], 'tenantId', ''),
                name: 'Tenant'
            },
            ...(organizationId && {
                OrganizationID: {
                    key: organizationId,
                    name: 'OrganizationID'
                }
            })
        }
    };

    useEffect(() => {
        const setupAccount = async () => {
            try {
                await API.post(SETUP_ACCOUNT_API);
                await dispatch(getCurrentProfile()).unwrap();
                setIsSetupAccountLoading(false);
            } catch (error) {
                alertErrorMessage(error);
            }
        };

        setupAccount();
    }, []);

    const handleToggleMenu = (isToggleIcon = false) => {
        const sidebar = document.getElementsByClassName('sidebar')[0];
        const container = document.getElementsByClassName('main')[0];

        if (sidebar.classList.contains('sidebar-responsive')) {
            sidebar.classList.remove('sidebar-responsive');
            container.classList.remove('main-blur');
        } else if (isToggleIcon) {
            sidebar.classList.add('sidebar-responsive');
            container.classList.add('main-blur');
        }
    };

    if (isSetupAccountLoading) {
        return (
            <div className="min-h-[100vh] bg-white flex flex-col justify-center items-center">
                <img src={fishwellLogo} alt="Fishwell Technology Logo" className="w-72" />
                <Spin></Spin>
            </div>
        );
    }

    const renderLayout = () => {
        return layout === LAYOUT_TYPES.DEFAULT ? (
            <LibLayout hasSider className="layout min-h-screen">
                <Sidebar />

                <AlignLeftOutlined className="toggle-icon" onClick={() => handleToggleMenu(true)} />

                <LibLayout
                    className="layout-container"
                    style={containerStyle}
                    onClick={() => handleToggleMenu()}
                >
                    <Header />

                    <Content className="main pt-3">
                        <ErrorBoundary FallbackComponent={SBGlobalBoundaryError}>
                            <Outlet />
                        </ErrorBoundary>
                    </Content>

                    <Footer />
                </LibLayout>
            </LibLayout>
        ) : (
            <LibLayout hasSider className="layout stress-detection-layout min-h-screen">
                <StressDetectionSidebar />

                <LibLayout className="layout-container" style={containerStyle}>
                    <AlignLeftOutlined className="toggle-icon" onClick={handleToggleMenu} />

                    <StressDetectionHeader />

                    <Content className="main pt-3">
                        <ErrorBoundary FallbackComponent={SBGlobalBoundaryError}>
                            <Outlet />
                        </ErrorBoundary>
                    </Content>

                    <StressDetectionFooter />
                </LibLayout>
            </LibLayout>
        );
    };

    const LDProvider = withLDProvider(ldProviderConfig)(renderLayout);

    return <LDProvider />;
};
export default Layout;
