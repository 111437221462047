import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import useAppAbility from 'casl/can';
import { FORBIDDEN_PAGE } from 'common/constants';

export default function ProtectedRoute({ children, isPrivate = false, role }) {
    const { inProgress } = useMsal();
    const { can } = useAppAbility();

    if (inProgress === InteractionStatus.Logout) return;

    if (isPrivate && !can('manage', role, ['all'])) {
        window.location.href = FORBIDDEN_PAGE;
        return;
    }

    return <>{children}</>;
}
