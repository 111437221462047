import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest, msalInstance } from 'authConfig';
import { SESSION_EXPIRED_PAGE } from 'common/constants';

export const getToken = async () => {
    try {
        const response = await msalInstance.acquireTokenSilent({
            account: msalInstance.getAllAccounts()[0],
            scopes: loginRequest.scopes,
            authority: localStorage.getItem('authority')
        });
        return response.idToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            try {
                const response = await msalInstance.acquireTokenRedirect({
                    account: msalInstance.getAllAccounts()[0],
                    scopes: loginRequest.scopes
                });
                return response.idToken;
            } catch (err) {
                localStorage.setItem('ERROR 1 FROM acquireTokenRedirect', JSON.stringify(err));
                window.location.href = SESSION_EXPIRED_PAGE;
            }
        } else {
            localStorage.setItem('ERROR 2 FROM acquireTokenSilent', JSON.stringify(error));
            window.location.href = SESSION_EXPIRED_PAGE;
        }
    }
};
