import { DatePicker, Space } from 'antd';
import { NORWEGIAN_DATE_FORMAT } from 'common/constants';
import PropTypes from 'prop-types';
import './SBRangeDate.scss';

const { RangePicker } = DatePicker;

const SBRangeDate = ({ defaultValue, value, placeholder, onChange = () => {} }) => {
    const handleRangeDateChange = (dates) => {
        var fromDate = dates && dates[0].startOf('day');
        var toDate = dates && dates[1].endOf('day');

        onChange(fromDate, toDate);
    };

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <RangePicker
                style={{ width: '100%' }}
                onChange={handleRangeDateChange}
                format={NORWEGIAN_DATE_FORMAT}
                defaultValue={defaultValue}
                value={value}
                placeholder={placeholder}
            />
        </Space>
    );
};

export default SBRangeDate;

SBRangeDate.propTypes = {
    defaultValue: PropTypes.array,
    value: PropTypes.array,
    placeholder: PropTypes.array,
    onChange: PropTypes.func
};
