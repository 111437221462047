import { Layout as LibLayout } from 'antd';
import { YYYY } from 'common/constants';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './Footer.scss';

const Footer = () => {
    const { t } = useTranslation();

    const { Footer } = LibLayout;

    return (
        <Footer className="footer">
            {`Fishwell Service Boat ${moment().format(YYYY)} ${t(
                'layout.footer.createdBy'
            )} Fishwell Technology`}
        </Footer>
    );
};

export default Footer;
