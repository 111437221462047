import map from 'lodash/map';
import PropTypes from 'prop-types';

const defaultGradient = {
    colors: ['#01a101', '#f2ff00d0', '#ffae00', '#FF0000'],
    stops: [0, 33.33, 66.67, 100]
};

export const oxygenGradient = {
    stops: [0, 38, 57, 70, 80]
};

export const generateGradientColor = ({
    stops = defaultGradient.stops,
    colors = defaultGradient.colors
} = {}) => {
    const gradientStops = map(stops, (stop, index) => `${colors[index]} ${stop}%`);

    return `linear-gradient(to bottom, ${gradientStops.join(', ')})`;
};

generateGradientColor.propTypes = {
    stops: PropTypes.arrayOf(PropTypes.number),
    colors: PropTypes.arrayOf(PropTypes.string)
};
