import { createAsyncThunk } from '@reduxjs/toolkit';
import { msalInstance } from 'authConfig';
import {
    STRESS_DETECTION_FARMER_COMPANY_SELECTION_API,
    STRESS_DETECTION_FARMER_SITE_SELECTION_API,
    STRESS_DETECTION_SERVICE_BOAT_SELECTION_API
} from 'common/constants';
import { GLOBAL_API } from 'services';

export const getStressDetectionFarmerCompaniesSelection = createAsyncThunk(
    'stressDetection/getStressDetectionFarmerCompaniesSelection',
    async (filter = {}, { rejectWithValue }) => {
        try {
            const { localAccountId } = msalInstance.getAllAccounts()[0];
            const { data } = await GLOBAL_API.get(
                `${STRESS_DETECTION_FARMER_COMPANY_SELECTION_API}/${localAccountId}`,
                filter
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getStressDetectionFarmerSitesSelection = createAsyncThunk(
    'stressDetection/getStressDetectionFarmerSitesSelection',
    async (filter = {}, { rejectWithValue }) => {
        try {
            const { localAccountId } = msalInstance.getAllAccounts()[0];
            const { data } = await GLOBAL_API.get(
                `${STRESS_DETECTION_FARMER_SITE_SELECTION_API}/${localAccountId}`,
                filter
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getStressDetectionServiceBoatsSelection = createAsyncThunk(
    'stressDetection/getStressDetectionServiceBoatsSelection',
    async (filter = {}, { rejectWithValue }) => {
        try {
            const { localAccountId } = msalInstance.getAllAccounts()[0];
            const { data } = await GLOBAL_API.get(
                `${STRESS_DETECTION_SERVICE_BOAT_SELECTION_API}/${localAccountId}`,
                filter
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
