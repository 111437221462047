import { getPreviousDate } from 'common/utils';

// Time period
export const TODAY = 0;
export const LAST_7_DAYS = 6;
export const LAST_30_DAYS = 29;
export const LAST_60_DAYS = 59;
export const PREVIOUS_DAYS_DEFAULT = LAST_7_DAYS;

export const TIME_PERIODS = [
    {
        id: 1,
        text: 'timePeriods.today',
        from: getPreviousDate(TODAY)
    },
    {
        id: 2,
        text: 'timePeriods.last7days',
        from: getPreviousDate(LAST_7_DAYS)
    },
    {
        id: 3,
        text: 'timePeriods.last30days',
        from: getPreviousDate(LAST_30_DAYS)
    },
    {
        id: 4,
        text: 'timePeriods.last60days',
        from: getPreviousDate(LAST_60_DAYS)
    },
    {
        id: 5,
        text: 'timePeriods.allTime',
        from: null
    }
];
export const TIME_PERIOD_DEFAULT = TIME_PERIODS[1];
