import { Col, Row } from 'antd';
import { SBMultipleSelection } from 'common/components';
import SBDate from 'common/components/Date';
import { BUTTON_BLUE_CLASS, Title } from 'common/constants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    getDailyReport,
    getFarmerCompaniesSelection,
    getFarmerPensSelection,
    getFarmerSitesSelection,
    getServiceBoatsSelection,
    updateFilters
} from 'redux/thunks';
import './FilterBar.scss';

const FilterBar = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const allPens = useSelector((s) => s.farmerPenSelection.data);
    const allSites = useSelector((s) => s.farmerSiteSelection.data);
    const companiesSelection = useSelector((s) => s.farmerCompanySelection.data);
    const serviceBoatsSelection = useSelector((s) => s.serviceBoatSelection.data);
    const initialFilters = useSelector((s) => s.filter.data);

    const [pensSelection, setPensSelection] = useState([]);
    const [sitesSelection, setSitesSelection] = useState([]);
    const [filters, setFilters] = useState({
        farmerOrganizationIds: initialFilters.farmerOrganizationIds,
        farmerSites: initialFilters.farmerSites,
        farmerPens: initialFilters.farmerPens,
        serviceBoatIds: initialFilters.serviceBoatIds,
        fromDate: initialFilters.dailyFromDate,
        toDate: initialFilters.dailyToDate
    });

    useEffect(() => {
        dispatch(getFarmerCompaniesSelection());
        dispatch(getFarmerSitesSelection());
        dispatch(getFarmerPensSelection());
        dispatch(getServiceBoatsSelection());
        dispatch(getDailyReport(filters));
    }, []);

    useEffect(() => {
        setPensSelection(allPens);
    }, [allPens]);

    useEffect(() => {
        setSitesSelection(allSites);
    }, [allSites]);

    const handleCompaniesChange = (farmerOrganizationIds) => {
        let newSitesSelections = allSites;
        let selectedSites = filters.farmerSites;

        let newPensSelections = allPens;
        let selectedPens = filters.farmerPens;

        if (farmerOrganizationIds.length) {
            newSitesSelections = allSites.filter((site) =>
                farmerOrganizationIds.includes(site.selectionObject.organizationId)
            );

            selectedSites = selectedSites.filter((site) =>
                newSitesSelections.some((siteSelection) => siteSelection.id === site.id)
            );

            if (selectedSites.length) {
                newPensSelections = allPens.filter((pen) =>
                    selectedSites.some(
                        (site) =>
                            site.selectionObject.localityNumber ===
                                pen.selectionObject.localityNumber &&
                            site.selectionObject.organizationId ===
                                pen.selectionObject.organizationId
                    )
                );
            } else {
                newPensSelections = allPens.filter((pen) =>
                    farmerOrganizationIds.includes(pen.selectionObject.organizationId)
                );
            }

            selectedPens = selectedPens.filter((pen) =>
                newPensSelections.some((penSelection) => penSelection.id === pen.id)
            );
        }

        setSitesSelection(newSitesSelections);
        setPensSelection(newPensSelections);
        setFilters({
            ...filters,
            farmerOrganizationIds: farmerOrganizationIds,
            farmerSites: selectedSites,
            farmerPens: selectedPens
        });
    };

    const handleSitesChange = (siteIds, sites) => {
        let selectedPens = filters.farmerPens;
        let newPensSelections = allPens;

        if (siteIds.length) {
            newPensSelections = allPens.filter((pen) =>
                sites.some(
                    (site) =>
                        site.selectionObject.localityNumber ===
                            pen.selectionObject.localityNumber &&
                        site.selectionObject.organizationId === pen.selectionObject.organizationId
                )
            );

            selectedPens = selectedPens.filter((pen) =>
                newPensSelections.some((penSelection) => penSelection.id === pen.id)
            );
        }

        setPensSelection(newPensSelections);
        setFilters({
            ...filters,
            farmerSites: sites,
            farmerPens: selectedPens
        });
    };

    const handlePensChange = (penIds, pens) => {
        setFilters({
            ...filters,
            farmerPens: pens
        });
    };

    const handleServiceBoatsChange = (serviceBoatIds) => {
        setFilters({
            ...filters,
            serviceBoatIds: serviceBoatIds
        });
    };

    const handleDateChange = (date) => {
        setFilters({
            ...filters,
            fromDate: date.clone().startOf('day'),
            toDate: date.clone().endOf('day')
        });
    };

    const handleFilterApply = () => {
        dispatch(getDailyReport(filters));
        dispatch(
            updateFilters({
                ...initialFilters,
                farmerOrganizationIds: filters.farmerOrganizationIds,
                farmerSites: filters.farmerSites,
                farmerPens: filters.farmerPens,
                serviceBoatIds: filters.serviceBoatIds,
                dailyFromDate: filters.fromDate,
                dailyToDate: filters.toDate
            })
        );
    };

    return (
        <div className="daily-report-filter-bar">
            <Title level={4}>{t('filterBar.title')}</Title>
            <Row className="md:text-base filter">
                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                    <div className="font-semibold">{t('filterBar.farmerCompany.title')}</div>
                    <SBMultipleSelection
                        placeholder={t('filterBar.farmerCompany.placeholder')}
                        listSelectItem={companiesSelection}
                        onChange={handleCompaniesChange}
                        value={filters.farmerOrganizationIds}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                    <div className="font-semibold">{t('filterBar.farmerSite.title')}</div>
                    <SBMultipleSelection
                        placeholder={t('filterBar.farmerSite.placeholder')}
                        listSelectItem={sitesSelection}
                        onChange={handleSitesChange}
                        value={filters.farmerSites.map((site) => site.id)}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                    <div className="font-semibold">{t('filterBar.farmerPen.title')}</div>
                    <SBMultipleSelection
                        placeholder={t('filterBar.farmerPen.placeholder')}
                        listSelectItem={pensSelection}
                        onChange={handlePensChange}
                        value={filters.farmerPens.map((pen) => pen.id)}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                    <div className="font-semibold">{t('filterBar.serviceBoat.title')}</div>
                    <SBMultipleSelection
                        placeholder={t('filterBar.serviceBoat.placeholder')}
                        listSelectItem={serviceBoatsSelection}
                        onChange={handleServiceBoatsChange}
                        value={filters.serviceBoatIds}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                    <div className="font-semibold">{t('filterBar.date.title')}</div>
                    <SBDate
                        placeholder={t('filterBar.date.placeholder')}
                        onChange={handleDateChange}
                        value={filters.fromDate}
                    />
                </Col>

                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={3} className="flex items-end">
                    <button className={`${BUTTON_BLUE_CLASS} !mb-0`} onClick={handleFilterApply}>
                        {t('filterBar.applyButton')}
                    </button>
                </Col>
            </Row>
        </div>
    );
};

export default FilterBar;
