import { Layout as LibLayout } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CountryPicker from './CountryPicker';
import DropdownProfile from './DropdownProfile';
import ServiceBoatList from './ServiceBoatList';
import ServicesDrawer from './ServicesDrawer';

const Header = () => {
    const { Header } = LibLayout;

    const { serviceDrawerSwitch } = useFlags();

    return (
        <Header theme="dark" className="header flex justify-between" style={{ zIndex: 10 }}>
            <ServiceBoatList />

            <div className="flex items-center gap-7 ">
                <CountryPicker />

                <DropdownProfile />

                {serviceDrawerSwitch && <ServicesDrawer />}
            </div>
        </Header>
    );
};

export default Header;
