import { createAsyncThunk } from '@reduxjs/toolkit';
import { DAILY_COUNT_API, DAILY_REPORT_API } from 'common/constants';
import { API } from 'services';

export const getDailyReport = createAsyncThunk(
    'reports/getDailyReport',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.post(DAILY_REPORT_API, {
                ...filters,
                farmerSites: filters.farmerSites.map((site) => site.selectionObject),
                farmerPens: filters.farmerPens.map((pen) => pen.selectionObject)
            });
            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const getDailyCount = createAsyncThunk(
    'reports/getDailyCount',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.get(DAILY_COUNT_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);
