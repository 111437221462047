import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'AppInsights';
import FilterBar from './FilterBar';
import ReportTable from './ReportTable';

const Daily = () => {
    return (
        <div className="daily-report">
            <FilterBar />
            <ReportTable />
        </div>
    );
};

export default withAITracking(reactPlugin, Daily);
