import { Card, Spin } from 'antd';
import { SBSelection } from 'common/components';
import { SBBubble } from 'common/components/Chart';
import {
    BLUE,
    EVENT_DETAIL_PAGE,
    NORWEGIAN_DATE_FORMAT,
    TIME_PERIODS,
    TIME_PERIOD_DEFAULT,
    Title
} from 'common/constants';
import { formatDateTime } from 'common/utils';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getEventChart, updateFilters } from 'redux/thunks';
import moment from 'moment';
import './SBEventChart.scss';

const SBEventChart = ({ boatId, className = '', eventChartClassName = '' }) => {
    const EVENT_SIZE = 20;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation();

    const initialFilters = useSelector((s) => s.filter.data);
    const { data: eventData = [], loading = false } = useSelector((s) => s.eventChart);

    useEffect(() => {
        dispatch(
            updateFilters({
                ...initialFilters,
                fromDate: initialFilters.timePeriod.fromDate
            })
        );
    }, []);

    useEffect(() => {
        const { fromDate, toDate } = initialFilters.timePeriod;
        const serviceBoatIds = boatId ? [boatId] : [];

        dispatch(getEventChart({ serviceBoatIds, fromDate, toDate }));
    }, [boatId, initialFilters.timePeriod]);

    const formattedData = eventData.flatMap((item) => {
        let y = 1;
        return item?.events?.map((event) => ({
            id: event.eventId,
            x: formatDateTime(item.date, NORWEGIAN_DATE_FORMAT),
            y: y++,
            r: EVENT_SIZE,
            type: event.eventType?.charAt(0)
        }));
    });

    const labels = eventData.map((item) => formatDateTime(item.date, NORWEGIAN_DATE_FORMAT));

    const eventChartData = {
        labels,
        datasets: [
            {
                data: formattedData,
                backgroundColor: BLUE
            }
        ]
    };

    const handlePeriodChange = (value, object) => {
        const timePeriod = {
            value,
            fromDate: object.from,
            toDate: object.from && moment().endOf('day')
        };

        dispatch(
            updateFilters({
                ...initialFilters,
                timePeriod,
                fromDate: timePeriod.fromDate,
                toDate: timePeriod.toDate
            })
        );
    };

    const handleEventClick = (eventId) => {
        dispatch(
            updateFilters({
                ...initialFilters,
                selectedEventId: eventId,
                farmerOrganizationIds: [],
                farmerSites: [],
                farmerPens: [],
                serviceBoatIds: boatId ? [boatId] : []
            })
        );
        navigate(EVENT_DETAIL_PAGE, { state: { navigatedEventId: eventId } });
    };

    return (
        <div className={`event-chart ${className}`}>
            <div className="title flex">
                <Title level={4}>{t('eventChart.title')}</Title>
                <SBSelection
                    placeholder="Select a period"
                    listSelectItem={TIME_PERIODS.map((period) => ({
                        ...period,
                        text: t(period.text)
                    }))}
                    defaultValue={TIME_PERIOD_DEFAULT.id}
                    className="w-40 ml-2"
                    onChange={handlePeriodChange}
                    value={initialFilters.timePeriod.value}
                />
            </div>
            <Spin spinning={loading}>
                <Card>
                    <p className="font-medium text-base m-0">{t('dashboard.events')}</p>
                    <div className={eventChartClassName || 'h-[500px]'}>
                        <SBBubble
                            maintainAspectRatio={false}
                            data={eventChartData}
                            onItemClick={handleEventClick}
                        />
                    </div>
                </Card>
            </Spin>
        </div>
    );
};

SBEventChart.propTypes = {
    boatId: PropTypes.string,
    className: PropTypes.string,
    eventChartClassName: PropTypes.string
};

export default SBEventChart;
