import { Ability, AbilityBuilder } from '@casl/ability';
import { ADMINISTRATOR, CAPTAIN, USER } from 'common/constants';

const defineAbilityFor = (groups = []) => {
    const { rules, can } = new AbilityBuilder(Ability);
    groups.forEach((group, _) => {
        switch (group) {
            case ADMINISTRATOR:
                can('manage', ADMINISTRATOR, ['all']);
                can('manage', CAPTAIN, ['all']);
                can('manage', USER, ['all']);
                break;
            case CAPTAIN:
                can('manage', CAPTAIN, ['all']);
                can('manage', USER, ['all']);
                break;
            case USER:
                can('manage', USER, ['all']);
                break;
            default:
                break;
        }
    });

    return new Ability(rules);
};

export default defineAbilityFor;
