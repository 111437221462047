import { createSlice } from '@reduxjs/toolkit';
import {
    createServiceBoat,
    getServiceBoats,
    updateServiceBoat,
    restoreServiceBoat,
    deleteServiceBoat
} from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const serviceBoatSlice = createSlice({
    name: 'serviceBoat',
    initialState,
    reducers: {},
    extraReducers: {
        [getServiceBoats.pending]: (state) => {
            state.loading = true;
        },
        [getServiceBoats.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
        },
        [getServiceBoats.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        },

        [createServiceBoat.pending]: (state) => {
            state.loading = true;
        },
        [createServiceBoat.fulfilled]: (state) => {
            state.loading = false;
        },
        [createServiceBoat.rejected]: (state) => {
            state.loading = false;
        },

        [updateServiceBoat.pending]: (state) => {
            state.loading = true;
        },
        [updateServiceBoat.fulfilled]: (state) => {
            state.loading = false;
        },
        [updateServiceBoat.rejected]: (state) => {
            state.loading = false;
        },

        [deleteServiceBoat.pending]: (state) => {
            state.loading = true;
        },
        [deleteServiceBoat.fulfilled]: (state) => {
            state.loading = false;
        },
        [deleteServiceBoat.rejected]: (state) => {
            state.loading = false;
        },

        [restoreServiceBoat.pending]: (state) => {
            state.loading = true;
        },
        [restoreServiceBoat.fulfilled]: (state) => {
            state.loading = false;
        },
        [restoreServiceBoat.rejected]: (state) => {
            state.loading = false;
        }
    }
});
