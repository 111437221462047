import { Layout } from 'antd';
import { STRESS_DETECTION_PAGE } from 'common/constants';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
    const { Sider } = Layout;
    const [collapsed] = useState(false);

    return (
        <Sider trigger={null} className="sidebar" collapsible collapsed={collapsed} width="250">
            <Link to={STRESS_DETECTION_PAGE}>
                <div className={`brand-name ${collapsed && 'collapsed'}`}>
                    <div className="logo" />
                </div>
            </Link>
        </Sider>
    );
};

export default Sidebar;
