import { createAsyncThunk } from '@reduxjs/toolkit';
import { STRESS_DETECTION_ENGINE_STATUS_API } from 'common/constants';
import { ENGINE_API } from 'services';

export const getStressDetectionEngineStatus = createAsyncThunk(
    'stressDetection/getStressDetectionEngineStatus',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await ENGINE_API.get(STRESS_DETECTION_ENGINE_STATUS_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
