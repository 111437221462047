import React from 'react';
import { Bubble } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SBBubble = ({ maintainAspectRatio = true, data = {}, onItemClick = () => {} }) => {
    const { t } = useTranslation();

    const handleItemClick = (id) => {
        onItemClick(id);
    };

    const options = {
        scales: {
            x: {
                type: 'category', // Set the x-axis scale type to 'category'
                beginAtZero: false,
                ticks: {
                    align: 'center',
                    padding: 10
                }
            },
            y: {
                display: false,
                beginAtZero: true,
                ticks: {
                    stepSize: 1 // Set the step size between each milestone,
                }
            }
        },
        pointStyle: 'rectRounded',
        plugins: {
            legend: {
                display: false // Turn off the legend bar
            },
            tooltip: {
                callbacks: {
                    title: (context) => {
                        const { dataset, dataIndex } = context[0];
                        const dataPoint = dataset.data[dataIndex];
                        return dataPoint.x;
                    },
                    label: (context) => {
                        const { dataset, dataIndex } = context;
                        const dataPoint = dataset.data[dataIndex];
                        const { type } = dataPoint;
                        return `${t('eventChart.eventType')}: ${type}`;
                    }
                }
            },
            datalabels: {
                display: true,
                color: 'white',
                align: 'center',
                formatter: (value) => value.type
            }
        },
        maintainAspectRatio: maintainAspectRatio,
        onClick: (event, elements) => {
            if (elements.length) {
                handleItemClick(elements[0].element.$context.raw.id);
            }
        }
    };

    return <Bubble options={options} data={data} className="cursor-pointer" />;
};

SBBubble.propTypes = {
    maintainAspectRatio: PropTypes.bool,
    data: PropTypes.object,
    onItemClick: PropTypes.func
};

export default SBBubble;
