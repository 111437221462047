import { createSlice } from '@reduxjs/toolkit';
import { getOriginalBoatsSelection } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const originalBoatSelectionSlice = createSlice({
    name: 'originalBoatSelection',
    initialState,
    reducers: {},
    extraReducers: {
        [getOriginalBoatsSelection.pending]: (state) => {
            state.loading = true;
        },
        [getOriginalBoatsSelection.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        [getOriginalBoatsSelection.rejected]: (state) => {
            state.loading = false;
        }
    }
});
