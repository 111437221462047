import i18n from 'translation/i18n';

export const STRESS_DETECTION_ENGINE_BASE_URL_KEY = 'STRESS_DETECTION_ENGINE_BASE_URL';

export const STRESS_ZONES = [
    {
        name: i18n.t('stressZone.extremelyUnacceptable'),
        min: 81,
        max: 100
    },
    {
        name: i18n.t('stressZone.unacceptable'),
        min: 61,
        max: 80
    },
    {
        name: i18n.t('stressZone.unwanted'),
        min: 41,
        max: 60
    },
    {
        name: i18n.t('stressZone.acceptable'),
        min: 21,
        max: 40
    },
    {
        name: i18n.t('stressZone.wanted'),
        min: 0,
        max: 20
    }
];
