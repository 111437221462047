// notification color
export const GREEN = '#50a06e';
export const BLUE = '#0369a1';
export const RED = '#f44a4a';
export const GRAY = '#334155';
export const YELLOW = '#eab308';
export const ORANGE = '#f97316';
export const LIGHT_GREEN = '#01a101';
export const LIGHT_YELLOW = '#f2ff00d0';
export const LIGHT_ORANGE = '#ffae00';
export const LIGHT_RED = '#FF0000';
