import { createSlice } from '@reduxjs/toolkit';
import { getStressDetectionServiceBoatsSelection } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const stressDetectionServiceBoatSelectionSlice = createSlice({
    name: 'stressDetectionServiceBoatSelection',
    initialState,
    reducers: {},
    extraReducers: {
        [getStressDetectionServiceBoatsSelection.pending]: (state) => {
            state.loading = true;
        },
        [getStressDetectionServiceBoatsSelection.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
        },
        [getStressDetectionServiceBoatsSelection.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        }
    }
});
