import { createSlice } from '@reduxjs/toolkit';
import { PREVIOUS_DAYS_DEFAULT, TIME_PERIOD_DEFAULT } from 'common/constants';
import { getPreviousDate } from 'common/utils';
import moment from 'moment';
import { updateFilters } from 'redux/thunks';

const initialState = {
    data: {
        dailyFromDate: moment().startOf('day'),
        dailyToDate: moment().endOf('day'),
        fromDate: getPreviousDate(PREVIOUS_DAYS_DEFAULT),
        toDate: moment().endOf('day'),
        timePeriod: {
            value: TIME_PERIOD_DEFAULT.id,
            fromDate: getPreviousDate(PREVIOUS_DAYS_DEFAULT),
            toDate: moment().endOf('day')
        },
        farmerOrganizationIds: [],
        farmerSites: [],
        farmerPens: [],
        serviceBoatIds: [],
        selectedEventId: null
    },
    loading: false
};

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {},
    extraReducers: {
        [updateFilters.pending]: (state) => {
            state.loading = true;
        },
        [updateFilters.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
        },
        [updateFilters.rejected]: (state) => {
            state.loading = false;
            state.data = initialState.data;
        }
    }
});
