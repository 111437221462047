import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER_API } from 'common/constants';
import { API } from 'services';

export const getUsers = createAsyncThunk('user/getUsers', async (filters, { rejectWithValue }) => {
    try {
        const { data } = await API.get(USER_API, filters);
        return data;
    } catch (err) {
        return rejectWithValue(err);
    }
});

export const updateUser = createAsyncThunk(
    'user/updateUser',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${USER_API}/${id}`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
