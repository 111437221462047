import {
    AreaChartOutlined,
    FileDoneOutlined,
    HomeOutlined,
    PicCenterOutlined,
    UsergroupAddOutlined
} from '@ant-design/icons';
import { useIsAuthenticated } from '@azure/msal-react';
import { Layout as LibLayout, Menu } from 'antd';
import useAppAbility from 'casl/can';
import {
    ADMINISTRATOR,
    CAPTAIN,
    DAILY_PAGE,
    DASHBOARD_PAGE,
    EVENT_DETAIL_PAGE,
    SERVICE_BOAT_PAGE,
    USER,
    USER_PAGE
} from 'common/constants';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';
import i18n from 'translation/i18n';

const Sidebar = () => {
    const { t } = useTranslation();
    const { Sider } = LibLayout;
    const { can } = useAppAbility();
    const [collapsed] = useState(false);
    const isAuthenticated = useIsAuthenticated();

    const location = useLocation();

    const menu = useMemo(() => {
        if (!isAuthenticated) return [];
        return [
            {
                icon: <HomeOutlined />,
                title: <span>{t('layout.sidebar.dashboard')}</span>,
                path: DASHBOARD_PAGE,
                visible: can('manage', USER, ['all'])
            },
            {
                icon: <FileDoneOutlined />,
                title: <span>{t('layout.sidebar.daily')}</span>,
                path: DAILY_PAGE,
                visible: can('manage', USER, ['all'])
            },
            {
                icon: <AreaChartOutlined />,
                title: <span>{t('layout.sidebar.event')}</span>,
                path: EVENT_DETAIL_PAGE,
                visible: can('manage', USER, ['all'])
            },
            {
                icon: <PicCenterOutlined />,
                title: <span>{t('layout.sidebar.serviceBoat')}</span>,
                path: SERVICE_BOAT_PAGE,
                visible: can('manage', CAPTAIN, ['all'])
            },
            {
                icon: <UsergroupAddOutlined />,
                title: <span>{t('layout.sidebar.user')}</span>,
                path: USER_PAGE,
                visible: can('manage', ADMINISTRATOR, ['all'])
            }
        ].filter((ability) => {
            return ability.visible;
        });
    }, [isAuthenticated, i18n.language]);

    return (
        <Sider trigger={null} className="sidebar" collapsible collapsed={collapsed} width="250">
            <Link to="/">
                <div className={`brand-name ${collapsed && 'collapsed'}`}>
                    <div className="logo" />
                </div>
            </Link>
            <Menu
                theme="light"
                className="menu"
                selectedKeys={[location.pathname]}
                defaultSelectedKeys={['/']}
                mode="inline"
            >
                {menu.map((menuItem) => (
                    <Menu.Item key={menuItem.path} className={`item ${menuItem.className}`}>
                        <NavLink to={menuItem.path} className="flex items-center">
                            {menuItem.icon}
                            <span className="title">{menuItem.title}</span>
                        </NavLink>
                    </Menu.Item>
                ))}
            </Menu>
        </Sider>
    );
};

export default Sidebar;
