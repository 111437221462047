import { DownloadOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { FILE_EXPORT_FORMAT_DATE } from 'common/constants';
import { formatDateTime } from 'common/utils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from 'services';

const SBDropdownDownload = ({ options = [], fileName, exportApi, method = 'GET' }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const downloadOptions = (
        <Menu
            items={options.map((option, index) => {
                return {
                    label: option.label,
                    key: index,
                    icon: <DownloadOutlined />,
                    className: option.className,
                    onClick: () => exportExcelFile(exportApi, option.params, fileName)
                };
            })}
        />
    );

    const createLink = (res, fileName) => {
        const outputFilename = `${fileName}-${formatDateTime(
            Date.now(),
            FILE_EXPORT_FORMAT_DATE
        )}.xlsx`;

        const url = URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
    };

    const exportExcelFile = async (api, params, fileName) => {
        setLoading(true);

        switch (method) {
            case 'GET':
                await API.export(api, params)
                    .then((res) => createLink(res, fileName))
                    .catch((error) => {
                        throw Error(error);
                    });
                break;
            case 'POST':
                await API.postExport(api, params)
                    .then((res) => createLink(res, fileName))
                    .catch((error) => {
                        throw Error(error);
                    });
                break;
            default:
        }

        setLoading(false);
    };

    return (
        <Dropdown placement="bottomRight" arrow overlay={downloadOptions}>
            <div className="h-full flex items-end">
                <Button
                    loading={loading}
                    type="default"
                    shape="round"
                    className="btn-download report-download-button"
                >
                    <span>{t('general.download.title')}</span>
                    <DownOutlined />
                </Button>
            </div>
        </Dropdown>
    );
};

SBDropdownDownload.propTypes = {
    options: PropTypes.array,
    fileName: PropTypes.string,
    exportApi: PropTypes.string,
    method: PropTypes.string
};

export default SBDropdownDownload;
