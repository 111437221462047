import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    STRESS_DETECTION_ENGINE_START_API,
    STRESS_DETECTION_ENGINE_STOP_API
} from 'common/constants';
import { ENGINE_API } from 'services';

export const startStressDetection = createAsyncThunk(
    'stressDetection/startStressDetection',
    async (filter = {}, { rejectWithValue }) => {
        try {
            const { data } = await ENGINE_API.get(
                `${STRESS_DETECTION_ENGINE_START_API}/${filter.penNumber}/${filter.localityNumber}/${filter.farmerOrganizationId}/${filter.eventType}`
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const stopStressDetection = createAsyncThunk(
    'stressDetection/stopStressDetection',
    async (filter = {}, { rejectWithValue }) => {
        try {
            const { data } = await ENGINE_API.get(
                `${STRESS_DETECTION_ENGINE_STOP_API}/${filter.penNumber}/${filter.localityNumber}/${filter.farmerOrganizationId}/${filter.eventType}`
            );
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
