import { Line } from '@ant-design/plots';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SBLine = ({
    isSingleLine = false,
    data = [],
    link = null,
    height,
    color,
    style,
    chartConfig = {},
    slider
}) => {
    const config = {
        height: height,
        data,
        xField: 'time',
        yField: 'value',
        seriesField: !isSingleLine && 'type',
        smooth: true,
        animation: false,
        legend: {
            position: 'top'
        },
        color: color,
        ...chartConfig,
        xAxis: {
            label: {
                style: {
                    fontSize: 11,
                    fill: 'black'
                }
            },
            ...chartConfig.xAxis
        },
        yAxis: {
            label: {
                style: {
                    fontSize: 11,
                    fill: 'black'
                }
            },
            ...chartConfig.yAxis
        },
        slider
    };

    return link ? (
        <Link to={link} className="cursor-pointer">
            <Line {...config} />
        </Link>
    ) : (
        <Line {...config} style={style} />
    );
};

SBLine.propTypes = {
    data: PropTypes.array,
    link: PropTypes.string,
    isSingleLine: PropTypes.bool,
    height: PropTypes.number,
    color: PropTypes.string,
    chartConfig: PropTypes.object,
    style: PropTypes.object,
    slider: PropTypes.object
};

export default SBLine;
