import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'AppInsights';
import GeneralInfo from './GeneralInfo';
import { SBEventChart } from 'components';
import ServiceBoatList from './ServiceBoatList';

const DashBoard = () => {
    return (
        <div className="dashboard">
            <GeneralInfo />
            <SBEventChart className="mt-5" />
            <ServiceBoatList />
        </div>
    );
};

export default withAITracking(reactPlugin, DashBoard);
