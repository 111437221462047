import { SBDropdownDownload, SBTable } from 'common/components';
import {
    DAILY_REPORT_EXPORT_API,
    DAILY_REPORT_NAME,
    EXPORT_ALL,
    EXPORT_BY_FILTER,
    NORWEGIAN_DATE_TIME_FORMAT,
    Title
} from 'common/constants';
import { formatDateTime } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';

const ReportTable = () => {
    const { t } = useTranslation();

    const { data: reports = [], loading = false } = useSelector((s) => s.dailyReport);
    const initialFilters = useSelector((s) => s.filter.data);

    const COLUMNS_FOR_DAILY_REPORT = [
        {
            title: t('daily.table.site'),
            dataIndex: 'siteName'
        },
        {
            title: t('daily.table.pen'),
            dataIndex: 'penNumber'
        },
        {
            title: t('daily.table.boat'),
            dataIndex: 'boatName'
        },
        {
            title: t('daily.table.startTime'),
            dataIndex: 'startTime',
            render: (startTime) => formatDateTime(startTime, NORWEGIAN_DATE_TIME_FORMAT),
            sorter: {
                compare: (a, b) => moment(a.startTime) - moment(b.startTime),
                multiple: 2
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            title: t('daily.table.stopTime'),
            dataIndex: 'stopTime',
            render: (stopTime) => formatDateTime(stopTime, NORWEGIAN_DATE_TIME_FORMAT),
            sorter: {
                compare: (a, b) => moment(a.stopTime) - moment(b.stopTime),
                multiple: 1
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            title: t('daily.table.eventType'),
            dataIndex: 'eventType'
        }
    ];

    const downloadOptions = [
        {
            label: t('general.download.currentFilter'),
            params: {
                farmerOrganizationIds: initialFilters.farmerOrganizationIds,
                farmerSites: initialFilters.farmerSites.map((site) => site.selectionObject),
                farmerPens: initialFilters.farmerPens.map((pen) => pen.selectionObject),
                serviceBoatIds: initialFilters.serviceBoatIds,
                fromDate: initialFilters.dailyFromDate,
                toDate: initialFilters.dailyToDate,
                exportType: EXPORT_BY_FILTER
            }
        },
        {
            label: t('general.download.allData'),
            params: {
                fromDate: initialFilters.dailyFromDate,
                toDate: initialFilters.dailyToDate,
                exportType: EXPORT_ALL
            }
        }
    ];

    return (
        <div className="daily-report-table mt-5">
            <div className="flex justify-between">
                <Title level={4}>{t('daily.title')}</Title>
                <SBDropdownDownload
                    options={downloadOptions}
                    exportApi={DAILY_REPORT_EXPORT_API}
                    fileName={DAILY_REPORT_NAME}
                    method={'POST'}
                />
            </div>

            <SBTable
                columns={COLUMNS_FOR_DAILY_REPORT}
                data={reports}
                className="report-table"
                rowClassName="table-row"
                loading={loading}
            />
        </div>
    );
};

export default ReportTable;
