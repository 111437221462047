import {
    ADMINISTRATOR,
    CAPTAIN,
    DAILY_PAGE,
    DASHBOARD_PAGE,
    EVENT_DETAIL_PAGE,
    FORBIDDEN_PAGE,
    LAYOUT_TYPES,
    PROFILE_PAGE,
    SERVICE_BOAT_DETAIL_PAGE,
    SERVICE_BOAT_PAGE,
    SESSION_EXPIRED_PAGE,
    STRESS_DETECTION_PAGE,
    UNAUTHORIZED_PAGE,
    USER,
    USER_PAGE
} from 'common/constants';
import Layout from 'common/layouts';
import {
    Daily,
    DashBoard,
    Event,
    Forbidden,
    NotFound,
    Profile,
    ServiceBoat,
    ServiceBoatDetail,
    SessionExpired,
    StressDetection,
    Unauthorized
} from 'pages';
import User from 'pages/user';

const routes = [
    {
        path: FORBIDDEN_PAGE,
        name: 'Forbidden',
        element: <Forbidden />
    },
    {
        path: UNAUTHORIZED_PAGE,
        name: 'Unauthorized',
        element: <Unauthorized />
    },
    {
        path: SESSION_EXPIRED_PAGE,
        name: 'Session Expired',
        element: <SessionExpired />
    },
    {
        path: STRESS_DETECTION_PAGE,
        element: <Layout layout={LAYOUT_TYPES.STRESS_DETECTION} />,
        isPrivate: true,
        role: USER,
        children: [
            {
                path: STRESS_DETECTION_PAGE,
                name: 'Stress detection',
                element: <StressDetection />,
                isPrivate: true,
                role: USER
            }
        ]
    },
    {
        path: '/',
        element: <Layout />,
        isPrivate: true,
        role: USER,
        children: [
            {
                path: DASHBOARD_PAGE,
                name: 'Dashboard',
                element: <DashBoard />,
                isPrivate: true,
                role: USER
            },
            {
                path: DAILY_PAGE,
                name: 'Daily',
                element: <Daily />,
                isPrivate: true,
                role: USER
            },
            {
                path: EVENT_DETAIL_PAGE,
                name: 'Event',
                element: <Event />,
                isPrivate: true,
                role: USER
            },
            {
                path: SERVICE_BOAT_PAGE,
                name: 'Service Boat',
                element: <ServiceBoat />,
                isPrivate: true,
                role: CAPTAIN
            },
            {
                path: PROFILE_PAGE,
                name: 'Profile',
                element: <Profile />,
                isPrivate: true,
                role: USER
            },
            {
                path: `${SERVICE_BOAT_DETAIL_PAGE}/:boatId`,
                name: 'Boat detail',
                element: <ServiceBoatDetail />,
                isPrivate: true,
                role: USER
            },
            {
                path: USER_PAGE,
                name: 'User',
                element: <User />,
                isPrivate: true,
                role: ADMINISTRATOR
            }
        ]
    },
    {
        path: '*',
        element: <NotFound />
    }
];

export default routes;
