import { STRESS_DETECTION_ENGINE_BASE_URL_KEY } from 'common/constants';

export const getStressDetectionEngineBaseUrl = () => {
    return localStorage.getItem(STRESS_DETECTION_ENGINE_BASE_URL_KEY);
};

export const existStressDetectionEngineBaseUrl = () => {
    return localStorage.getItem(STRESS_DETECTION_ENGINE_BASE_URL_KEY) !== null;
};

export const saveStressDetectionEngineBaseUrl = (url) => {
    localStorage.setItem(STRESS_DETECTION_ENGINE_BASE_URL_KEY, url);
};

export const removeStressDetectionEngineBaseUrl = () => {
    localStorage.removeItem(STRESS_DETECTION_ENGINE_BASE_URL_KEY);
};
